import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'nzk-react-components'
import useData from '../../data/Assignments/useData'
import useAsync from '../../../../../hooks/useAsync'
import Loader from '../../../../UI/Loader'

const Wrapper = styled.div`
  margin-top: 12px;
`

const Results = styled.div`
  position: relative;
  margin-top: 8px;
  > * { margin-bottom: 6px; }
  > :last-child { margin-bottom: 0; }
`

const Result = styled.div`
  cursor: pointer;
  background-color: #ebebeb;
  padding: 6px;
  border-radius: 4px;
  display: flex;
  p {
    margin-bottom: 4px;
  }
`

const Content = styled.div``

interface IProps {
  templateId: string
  nbTemplates?: number
  onFeedbackSelected: (feedback: string) => void
}

const FeedbackSuggestions = (props: IProps) => {
  const [shownResults, setShownResults] = useState(true)
  const { getTemplateFeedbacks } = useData()

  const { data, loading, execute } = useAsync({
    asyncFunc: (params) => getTemplateFeedbacks(params.templateId, params.skip, params.limit, params.refetch),
    funcParams: {
      templateId: props.templateId,
      skip: 0,
      limit: props.nbTemplates,
      refetch: false
    },
    initialData: [],
    immediate: true
  })

  useEffect(() => {
    if (shownResults) {
      execute({
        templateId: props.templateId,
        skip: 0,
        limit: props.nbTemplates,
        refetch: true
      })
    }
  }, [shownResults])

  return <Wrapper>
    <Button theme='primary' size='x-small' onClick={() => setShownResults(d => !d)}>Suggestions</Button>
    { shownResults && <Results>
      {
        !loading ? data.map(d => <Result key={d.createdAt} onClick={() => props.onFeedbackSelected(d.mainFeedback)}>
          <Content dangerouslySetInnerHTML={{ __html: d.mainFeedback }} />
        </Result>) : <Loader />
      }
    </Results> }
  </Wrapper>
}

FeedbackSuggestions.defaultProps = {
  nbTemplates: 3
}

export default FeedbackSuggestions
