import { Select } from 'grommet'
import React, { useEffect, useRef, useState } from 'react'
import { TwitterPicker } from 'react-color'
import styled from 'styled-components'
import { useAssignmentReview } from './index.state'
import BasicEditor from '../../../BasicEditor'
import FeedbackSuggestions from '../components/FeedbackSuggestions'

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  textarea {
    resize: vertical;
  }
`

const Field = styled.div`
  margin-bottom: 14px;
  > :first-child {
    margin-bottom: 7px;
    font-weight: bold;
  }
`

const Title = styled.div`
  margin-bottom: 12px;
  font-family: 'Rammetto One';
  text-align: center;
`

const Comments = styled.div`
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Comment = styled.div<{ accent: string, expanded: boolean }>`
  padding: 10px;
  box-shadow: 0 -5px 0 ${props => props.accent};
  border-radius: 6px;
  border: 1px solid ${props => props.accent};

  blockquote {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid #afafaf;
  }
`

const Feedback = () => {
  const { review, updateMainfeedback, editor, selectedComment, assignment, languagetool } = useAssignmentReview()
  const [expandedComment, setExpandedComment] = useState<string | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const range = editor?.state.selection.ranges[0]
    if (range) {
      setExpandedComment(`${range.$from.pos}-${range.$to.pos}`)
    }
  }, [selectedComment])

  const updateComment = (comment) => {
    editor?.chain()
      .setTextSelection({ from: comment.fromChar, to: comment.toChar })
      .setComment(comment)
      .run()
  }

  const scrollToComment = (commentId: string) => {
    if (typeof window !== 'undefined') {
      const el: HTMLDivElement | null = document.querySelector(`#comment-${commentId}`)
      if (el && wrapperRef.current) {
        wrapperRef.current?.scrollTo({
          top: el.offsetTop - wrapperRef.current.offsetTop - 20,
          behavior: 'smooth'
        })
      }
    }
  }

  useEffect(() => {
    if (expandedComment) {
      scrollToComment(expandedComment)
    }
  }, [expandedComment])

  const expand = (comment) => {
    if (expandedComment === `${comment.fromChar}-${comment.toChar}`) return
    setExpandedComment(`${comment.fromChar}-${comment.toChar}`)
  }
  
  return <Wrapper id='feedback' ref={wrapperRef}>
    <Title>Feedback</Title>
    <Field>
      <div>Main feedback</div>
      <BasicEditor value={review.mainFeedback} onHTMLChange={updateMainfeedback} />
      <FeedbackSuggestions templateId={assignment.templateId} onFeedbackSelected={updateMainfeedback} />
    </Field>
    <Field>
      <div>Comments</div>
      <Comments id='comments'>
      {
        review.comments.map((c) => <Comment id={c.id} key={c.id} accent={c.color} expanded={expandedComment === `${c.fromChar}-${c.toChar}`} onClick={() => expand(c)}>
          { expandedComment !== `${c.fromChar}-${c.toChar}` && <>
            <div>[{c.fromChar}-{c.toChar}] ({c.id})</div>
            <div>
              <blockquote>
                {c.text}
              </blockquote>
            </div>
          </> }
          { expandedComment === `${c.fromChar}-${c.toChar}` && <>
          <div>From {c.fromChar} to {c.toChar} ({c.id})</div>
          <Field>
            <div>Text</div>
            <blockquote>
              {c.text}
            </blockquote>
          </Field>
          { c.type === 'CUSTOM' && <Field>
            <div>Color</div>
            <TwitterPicker color={c.color} onChange={(color) => updateComment({ ...c, color: color.hex })} />
          </Field> }
          <Field>
            <div>Type</div>
            <Select options={['NOTE', 'MISTAKE', 'CHANGE', 'CUSTOM']} value={c.type} onChange={({ option }) => updateComment({ ...c, type: option })} />
          </Field>
          <Field>
            <div>Message</div>
            <BasicEditor value={c.message} onHTMLChange={(html) => updateComment({ ...c, message: html })} />
          </Field></> }
        </Comment>)
      }
      </Comments>
    </Field>
  </Wrapper>
}

export default Feedback
