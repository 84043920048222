import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useAssignmentReview } from '../index.state'
import Comment, { findComments, getCurrentComment } from './extensions/comment'
import NewCommentFloatingMenu from './NewCommentFloatingMenu'


const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.5;
  height: 100%;
  > div > .ProseMirror {
    padding: 20px;
    height: 100%;
    border: none;
    outline: none;
  }
  span[data-message] {
    padding: 0 2px;
    border-radius: 4px;
  }
  span[data-message]::selection {
    background-color: inherit;
    user-select: all;
  }
  
`

const Writing = () => {
  const { assignment, selectComment, updateReview, setEditor } = useAssignmentReview()

  const editor = useEditor({
    extensions: [
      StarterKit,
      Comment.configure({ isCommentModeOn: () => true })
    ],
    content: assignment.starTutoringReview?.annotatedText || assignment.writing.text,
    // content: MOCK_VALUE,
    onCreate: ({ editor }) => {
      // editor.setEditable(false)
      const comments = findComments(editor)
      updateReview({
        comments: comments.map(c => ({
          id: c.comment.id,
          message: c.comment.message,
          type: c.comment.type,
          text: c.text,
          color: c.comment.color,
          fromChar: c.from,
          toChar: c.to,
        })),
        annotatedText: editor.getHTML()
      })
    },
    onUpdate: ({ editor }) => {
      const comments = findComments(editor)
      updateReview({
        comments: comments.map(c => ({
          id: c.comment.id,
          message: c.comment.message,
          type: c.comment.type,
          text: c.text,
          color: c.comment.color,
          fromChar: c.from,
          toChar: c.to,
        })),
        annotatedText: editor.getHTML()
      })
    },
    onSelectionUpdate: ({ editor }) => {
      const comment = getCurrentComment(editor)
      selectComment(comment)
    }
  })

  useEffect(() => {
    if (editor) {
      setEditor(editor)
    }
  }, [editor])

  return <Wrapper>
    <NewCommentFloatingMenu editor={editor} />
    <EditorContent editor={editor} />
  </Wrapper>
}

export default Writing

