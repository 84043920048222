import { useApolloClient } from '@apollo/client'
import GET_ASSIGNMENTS_TO_REVIEW from './graphql/getAssignmentsToReview.graphql'
import GET_ASSIGNMENT_TO_REVIEW from './graphql/getAssignmentToReview.graphql'
import CREATE_ASSIGNMENT_REVIEW from './graphql/createAssignmentReview.graphql'
import UPDATE_ASSIGNMENT_REVIEW from './graphql/updateAssignmentReview.graphql'
import GET_TEMPLATE_FEEDBACK from './graphql/getTemplateFeedbacks.graphql'

interface ICreateAssignmentReviewInput {
  mainFeedback: string
  draft?: boolean
  annotatedText: string
  comments: {
    type: "NOTE" | "MISTAKE" | "CHANGE" | "CUSTOM"
    color: string
    message: string
    fromChar: number
    toChar: number
  }[]
}

interface IAssignmentsFilters {
  shouldBeReviewed?: { eq?: boolean, $ne?: boolean }
}

const useData = () => {
  const client = useApolloClient()

  const fetchAssignmentsToReview = async (filters: IAssignmentsFilters, skip: number, limit: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_ASSIGNMENTS_TO_REVIEW,
      variables: {
        filters,
        skip,
        limit
      },
      fetchPolicy: refetch ? 'network-only' : undefined
    })
    return data.starTutoring_assignments
  }

  const fetchAssignmentToReview = async (_id: string, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_ASSIGNMENT_TO_REVIEW,
      variables: {
        _id
      },
      fetchPolicy: refetch ? 'network-only' : undefined
    })
    return data.assignment
  }

  const createAssignmentReview = async (assignmentId: string, input: ICreateAssignmentReviewInput) => {
    const { data } = await client.mutate({
      mutation: CREATE_ASSIGNMENT_REVIEW,
      variables: {
        assignmentId,
        input: {
          mainFeedback: input.mainFeedback,
          draft: input.draft,
          comments: input.comments,
        }
      },
    })
    return data.starTutoring_createAssignmentReview
  }

  const updateAssignmentReview = async (assignmentId: string, input: ICreateAssignmentReviewInput) => {
    const { data } = await client.mutate({
      mutation: UPDATE_ASSIGNMENT_REVIEW,
      variables: {
        assignmentId,
        input: {
          annotatedText: input.annotatedText,
          mainFeedback: input.mainFeedback,
          draft: input.draft,
          comments: input.comments,
        }
      },
    })
    return data.starTutoring_updateAssignmentReview
  }

  const getTemplateFeedbacks = async (templateId: string, skip: number, limit: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_TEMPLATE_FEEDBACK,
      variables: {
        templateId,
        skip,
        limit,
      },
      fetchPolicy: 'network-only'
    })
    return data.starTutoring_assignments.map(d => d.starTutoringReview).filter(d => d)
  }

  return {
    fetchAssignmentsToReview,
    fetchAssignmentToReview,
    createAssignmentReview,
    updateAssignmentReview,
    getTemplateFeedbacks
  }
}

export default useData
