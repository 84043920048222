import {
  Mark,
  getMarkRange,
  mergeAttributes,
} from '@tiptap/react'
import shortid from 'shortid'
import { Plugin, TextSelection } from 'prosemirror-state'

// https://github.com/sereneinserenade/tiptap-comment-extension/blob/main/src/components/Tiptap.vue
// https://sereneinserenade.github.io/tiptap-comment-extension/

export interface CommentOptions {
  HTMLAttributes: Record<string, any>,
  isCommentModeOn: () => boolean
}

export enum CommentType {
  "NOTE" = "NOTE",
  "MISTAKE" = "MISTAKE",
  "CHANGE" = "CHANGE"
}

const COLORS_FOR_TYPE: { [key in CommentType]: string } = {
  'NOTE': '#aad252',
  'MISTAKE': '#f9dc39',
  'CHANGE': '#EB144C'
}

interface IAttributes { id: string, message: string, color?: string, type: CommentType }

declare module '@tiptap/react' {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Set a comment mark
       */
      setComment: (attributes: IAttributes) => ReturnType,
      /**
       * Toggle a comment mark
       */
      toggleComment: (attributes: IAttributes) => ReturnType,
      /**
       * Unset a comment mark
       */
      unsetComment: () => ReturnType,
    }
  }
}

export const Comment = Mark.create<CommentOptions>({
  name: 'comment',

  addOptions () {
    return {
      HTMLAttributes: {},
      isCommentModeOn: () => false,
    }
  },

  addAttributes () {
    return {
      id: {
        parseHTML: element => element.getAttribute('data-id') || `${shortid.generate()}`,
        renderHTML: (attributes) => {
          return {
            'id': attributes.id,
            'data-id': `comment-${attributes.id}`
          }
        }
      },
      message: {
        default: null,
        parseHTML: element => element.getAttribute('data-message'),
        renderHTML: (attributes) => {
          if (!attributes.message) {
            return {}
          }
          return {
            'data-message': attributes.message
          }
        }
      },
      color: {
        default: null,
        parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: attributes => {
          if (!attributes.color) {
            return {}
          }
          return {
            'data-color': attributes.color,
            style: `background-color: ${attributes.color}66; border-bottom: 2px ${attributes.color} solid;`,
          }
        },
      },
      type: {
        default: null,
        parseHTML: element => element.getAttribute('data-type'),
        renderHTML: attributes => {
          if (!attributes.type) {
            return {}
          }

          return {
            'data-type': attributes.type,
          }
        },
      },
    }
  },

  parseHTML () {
    return [
      {
        tag: 'span[data-message]',
        getAttrs: (el) => !!(el as HTMLSpanElement).getAttribute('data-message')?.trim() && null,
      },
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return [ 'span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0 ]
  },

  addCommands () {
    return {
      setComment: (attributes) => ({ commands }) => {
        let { color, id } = attributes
        if (attributes.type && COLORS_FOR_TYPE[attributes.type]) color = COLORS_FOR_TYPE[attributes.type]
        if (!attributes.id) id = shortid.generate()
        return commands.setMark('comment', { ...attributes, id, color })
      },
      toggleComment: (attributes) => ({ commands }) => {
        let { color, id } = attributes
        if (attributes.type && COLORS_FOR_TYPE[attributes.type]) color = COLORS_FOR_TYPE[attributes.type]
        if (!attributes.id) id = shortid.generate()
        return commands.toggleMark('comment', { ...attributes, id, color })
      },
      unsetComment: () => ({ commands }) => commands.unsetMark('comment'),
    }
  },

  addProseMirrorPlugins () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const extensionThis = this

    const plugins = [
      new Plugin({
        // filterTransaction: (transaction, state) => {
        //   // if (transaction.docChanged && (
        //   //   // @ts-ignore
        //   //   transaction.updated === 5 || transaction.updated === 4
        //   // )) {
        //   //   return false
        //   // }
        //   return true
        // },
        props: {
          handleClick (view, pos) {
            if (!extensionThis.options.isCommentModeOn()) return false

            const { schema, doc, tr } = view.state

            const range = getMarkRange(doc.resolve(pos), schema.marks.comment)

            if (!range) return false

            const [ $start, $end ] = [ doc.resolve(range.from), doc.resolve(range.to) ]
            
            view.dispatch(tr.setSelection(new TextSelection($start, $end)))

            return true
          },
        },
      }),
    ]

    return plugins
  },
})


export const findComments = (editor: any) => {
  const tempComments: any[] = []
  editor.state.doc.descendants((node, pos) => {
    const { marks } = node
    marks.forEach((mark) => {
      if (mark.type.name === 'comment') {
        const comment = mark.attrs
        if (comment !== null) {
          tempComments.push({
            node,
            comment,
            from: pos,
            to: pos + (node.text?.length || 0),
            text: node.text,
          })
        }
      }
    })
  })
  return tempComments
}

export const getCurrentComment = (editor) => {
  const selected = editor.isActive('comment')
  if (selected) {
    const comment = editor.getAttributes('comment')
    return comment
  }
  return null
}

export default Comment
