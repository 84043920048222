import { useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next'
import { Editor } from '@tiptap/react'
import { navigate } from 'gatsby';
import shortid from 'shortid'
import useAsync from '../../../../hooks/useAsync';
import useData from '../data/Assignments/useData';
import { processText } from './utils/languagetool';
import { CommentType } from './WritingEditor/extensions/comment';

interface IInitialState {
  assignmentId: string
}

interface IAssignmentReviewComment {
  id: string
  type: "NOTE" | "MISTAKE" | "CHANGE" | "CUSTOM"
  color: string
  text: string
  message: string
  fromChar: number
  toChar: number
}

interface IEditorComment {
  color: string
  message: string
  type: "NOTE" | "MISTAKE" | "CHANGE" | "CUSTOM"
}

interface IAssignmentReview {
  annotatedText: string
  mainFeedback: string
  comments: IAssignmentReviewComment[]
}

interface IStarTutoringSubscription {
  _id: string
  message: string
}

const AssignmentReviewState = (initialState?: IInitialState) => {
  const { fetchAssignmentToReview, createAssignmentReview, updateAssignmentReview } = useData()
  const [selectedComment, selectComment] = useState<IEditorComment | null>(null)
  const [editor, setEditor] = useState<Editor | null>(null)
  const [started, setStarted] = useState(false)

  const [review, setReview] = useState<IAssignmentReview>({
    mainFeedback: '',
    annotatedText: '',
    comments: []
  })
  const [subscription, setSubscription] = useState<IStarTutoringSubscription | null>(null)

  const { data: assignment, loading, execute: refetch } = useAsync({
    asyncFunc: (params) => {
      return fetchAssignmentToReview(params._id, true)
    },
    funcParams: {
      _id: initialState?.assignmentId,
      refetch: false
    },
    immediate: true
  })

  useEffect(() => {
    setSubscription(assignment?.zookeeper?.starTutoring)
    if (assignment?.starTutoringReview) {
      setReview(assignment.starTutoringReview)
      setStarted(!assignment.shouldBeReviewed || assignment.starTutoringReview?.draft === true)
    }
  }, [assignment])

  const refresh = () => {
    refetch({ _id: initialState?.assignmentId, refetch: true })
  }

  const addComment = (comment: IAssignmentReviewComment) => {
    setReview((r) => ({
      ...r,
      comments: [...r.comments, comment]
    }))
  }

  const setComments = (comments: IAssignmentReviewComment[]) => {
    setReview((r) => ({
      ...r,
      comments
    }))
  }

  const updateMainfeedback = (mainFeedback: string) => {
    setReview((r) => ({
      ...r,
      mainFeedback
    }))
  }

  const updateReview = (payload: {
    comments?: IAssignmentReviewComment[],
    mainFeedback?: string,
    annotatedText?: string
  }) => {
    setReview((r) => ({
      ...r,
      ...payload
    }))
  }
  
  const start = async () => {
    if (initialState?.assignmentId) {
      await createAssignmentReview(initialState.assignmentId, review)      
      setStarted(true)
    }
  }

  const languagetool = async () => {
    const text = editor?.getText()
    if (!text || !editor) return
    const comments = await processText(text, editor)
    comments.map(c => {
        editor?.chain().setTextSelection(
          { from: c.from, to: c.to }
        ).setComment({
          id: shortid.generate(),
          type: c.type as CommentType,
          message: c.message
        }).run()
      })
  } 

  const canSubmit = useMemo(() => {
    return review?.mainFeedback
  }, [review])

  const submit = async () => {
    if (initialState?.assignmentId) {
      await updateAssignmentReview(initialState.assignmentId, { ...review, draft: false })
      navigate('/star-tutoring/reviews?refetch=true')
    }
  }

  return {
    review,
    editor,
    languagetool,
    subscription,
    setEditor,
    addComment,
    setComments,
    updateReview,
    selectedComment,
    selectComment,
    start,
    started,
    updateMainfeedback,
    refresh,
    assignment,
    loading,
    submit,
    canSubmit
  }
}

const AssignmentReviewStateContainer = createContainer(AssignmentReviewState)

export const useAssignmentReview = AssignmentReviewStateContainer.useContainer
export const AssignmentReviewProvider = AssignmentReviewStateContainer.Provider
