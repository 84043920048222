import React from 'react'
import styled from 'styled-components';
import { Button, Checkmark, IconButton, Refresh, Send } from 'nzk-react-components';
import Loader from '../../../UI/Loader'
import { AssignmentReviewProvider, useAssignmentReview } from './index.state';
import Writing from './WritingEditor';
import Feedback from './Feedback';
import 'twin.macro'
import WritingPrompt from './WritingPrompt';

const Wrapper = styled.div`
  max-height: calc(100vh - 145px);
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Columns = styled.div`
  display: grid;
  position: sticky;
  top: 167px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  > * {
    overflow: auto;
    max-height: calc(100vh - 191px);
  }
`

interface IProps {
  assignmentId: string
}

const AssignmentReview = () => {
  const { loading, refresh, submit, started, start, canSubmit, languagetool } = useAssignmentReview() 

  return <Wrapper>
    <Header>
      <div tw='all-child:mr-2 flex'>
        <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => refresh()}>Refresh</IconButton>
        <IconButton size='x-small' theme='confirm' icon={<Checkmark />} onClick={() => languagetool()}>Perform Spellcheck</IconButton>
      </div>
      <IconButton size='x-small' theme='confirm' icon={<Send />} onClick={submit} disabled={!canSubmit}>Submit</IconButton>
    </Header>
    { loading
      ? <Loader />
      : <>
          <WritingPrompt />
          <Columns>
            { started ? <Writing /> : <div tw="flex justify-center mt-12">
              <Button theme='confirm' size='large' onClick={start}>Start</Button>
            </div> }
            <Feedback />
          </Columns>
        </>
    }
  </Wrapper>
}

export default (props: IProps) => {
  return <AssignmentReviewProvider initialState={{ assignmentId: props.assignmentId }}>
    <AssignmentReview />
  </AssignmentReviewProvider>
}
