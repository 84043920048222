import { Editor, getMarkType, getNodeType } from '@tiptap/react'
import Axios from 'axios'
import sanitize from 'sanitize-html'

interface ILanguageToolMatch {
  message: string
  shortMessage: string
  replacements: {
    value: string
  }[]
  offset: number
  length: number
  context: {
    text: string
    offset: number
    length: number
  }
  sentence: string
  type: {
    typeName: string
  }
  rule: {
    id: string
    decription: string
    issueType: string
    category: {
      id: string
      name: string
    }
  }
}

const fetch = async (text: string): Promise<ILanguageToolMatch[]> => {
  const cleanText = sanitize(text, { allowedTags: [] })
  const { data } = await Axios.post('https://languagetool.nightzookeeper.com/v2/check',
  {
    data: JSON.stringify({ "text": cleanText }),
    language: 'en-US',
    // disabledRules: disabledRules.join(','),
    // disabledCategories: disabledCategories.join(','),
    // enableOnly: false
  }, {
    timeout: 10000, // timeout after 10 seconds in case languagetool is being slow
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: (jsonData = {}) => (
      Object.entries(jsonData)
        // @ts-ignore
        .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join('&'))
  })
  return data?.matches || []
}

const filterMatches = (matches: ILanguageToolMatch[]) => {
  return matches//.filter(m => m.rule.id === 'MORFOLOGIK_RULE_EN_US')
}

const getComment = (match: ILanguageToolMatch, offset: number) => {
  return {
    type: 'MISTAKE',
    message: `${match.replacements[0].value}`,
    from: match.offset + 1 + offset,
    to: match.offset + match.length + 1 + offset
  }
}

export const processText = async (text: string, editor: Editor) => {
  let filteredMatches: ILanguageToolMatch[] = []
  const matches = await fetch(text)
  filteredMatches = filterMatches(matches)
  let offsetsAt: number[] = []
  let offsets: number[] = []
  let currentOffset = 0
  editor.state.doc.descendants((node, pos) => {
    if (node.content.size === 0 && node.type === getNodeType('paragraph', editor.schema)) {
      currentOffset+=2
      offsetsAt.unshift(pos)
      offsets.unshift(currentOffset)
    }
  })
  return filteredMatches.map((match) => {
    const index = offsetsAt.findIndex(d => d < match.offset)
    return getComment(match, offsets[index] || 0)
  })
}