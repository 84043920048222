import React, { useEffect, useState } from 'react'
import { BubbleMenu } from '@tiptap/react'
import styled from 'styled-components'
import { Button, Checkmark, IconButton, Plus, TrashWhite } from 'nzk-react-components'
import { TwitterPicker } from 'react-color'
import { useAssignmentReview } from '../index.state'
import BasicEditor from '../../../../BasicEditor'
import { getCurrentComment } from './extensions/comment'

const FloatingMenuWrapper = styled.div`
  background-color: #FFF;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.4);
  > * { margin-bottom: 15px; }
  > :last-child { margin-bottom: 0; }
  .ProseMirror {
    max-height: 100px;
    overflow: auto;
    color: #000;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * { margin-right: 8px; }
  > :last-child { margin-right: 0; }
`

interface IProps {
  editor: any
}

const NewCommentFloatingMenu = (props: IProps) => {
  const { editor } = props
  const [color, setColor] = useState('')
  const [message, setMessage] = useState('')
  const [type, setType] = useState('MISTAKE')
  const { selectedComment, selectComment } = useAssignmentReview()

  useEffect(() => {
    setColor(selectedComment?.color || '')
    setMessage(selectedComment?.message || '')
    setType(selectedComment?.type || 'MISTAKE')
  }, [selectedComment])

  const addComment = () => {
    editor.chain().focus().toggleComment({ color, message, type }).blur().focus().run()
    setColor('')
    setMessage('')
    setType('MISTAKE')
    const comment = getCurrentComment(editor)
    selectComment(comment)
  }

  const removeComment = () => {
    editor.chain().focus().unsetComment().blur().run()
  }

  const updateComment = () => {
    editor?.chain()
      .setComment({ type, color, message })
      .run()
  }
  if  (!editor) return null
  return <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
      <FloatingMenuWrapper>
        { selectedComment && <BasicEditor value={message} onHTMLChange={(html) => setMessage(html)} /> }
        <Actions>
          <Button theme='primary' height={20} disabled={type !== 'NOTE'} onClick={() => setType('NOTE')}>NOTE</Button>
          <Button theme='primary' height={20} disabled={type !== 'MISTAKE'} onClick={() => setType('MISTAKE')}>MISTAKE</Button>
          <Button theme='primary' height={20} disabled={type !== 'CHANGE'} onClick={() => setType('CHANGE')}>CHANGE</Button>
          <Button theme='primary' height={20} disabled={type !== 'CUSTOM'} onClick={() => {
            setType('CUSTOM')
            setColor('#F18805')
          }}>Spelling</Button>
          <Button theme='primary' height={20} disabled={type !== 'CUSTOM'} onClick={() => {
            setType('CUSTOM')
            setColor('#A352B7')
          }}>Punctuation</Button>
          <Button theme='primary' height={20} disabled={type !== 'CUSTOM'} onClick={() => {
            setType('CUSTOM')
            setColor('#FFCF00')
          }}>Grammar</Button>
          <Button theme='primary' height={20} disabled={type !== 'CUSTOM'} onClick={() => {
            setType('CUSTOM')
            setColor('#83D537')
          }}>Vocabulary</Button>
          <Button theme='primary' height={20} disabled={type !== 'CUSTOM'} onClick={() => {
            setType('CUSTOM')
            setColor('#00B4D8')
          }}>Content</Button>
          <Button theme='primary' height={20} disabled={type !== 'CUSTOM'} onClick={() => setType('CUSTOM')}>CUSTOM</Button>
        </Actions>
        { type === 'CUSTOM' && <TwitterPicker color={color} onChange={(color) => setColor(color.hex)} /> }
        { editor.isActive('comment')
          ? <Actions>
            <IconButton
              size='x-small'
              theme='red'
              icon={<TrashWhite />}
              onClick={removeComment}>
              Remove
            </IconButton>
            <IconButton
              size='x-small'
              theme='confirm'
              icon={<Checkmark />}
              onClick={updateComment}>
              Save
            </IconButton>
          </Actions>
          : <IconButton
              size='x-small'
              theme='confirm'
              icon={<Plus />}
              onClick={addComment}>
              Add
            </IconButton>
        }
      </FloatingMenuWrapper>
    </BubbleMenu>
}

export default NewCommentFloatingMenu
