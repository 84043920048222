import { Avatar } from 'nzk-react-components'
import React from 'react'
import 'twin.macro'
import styled from 'styled-components'
import { useAssignmentReview } from '../index.state'

const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
`

const Title = styled.h2`

`

const Content = styled.div`

`

const Targets = styled.div`
  h4 {
    margin: 0;
    margin-bottom: 8px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    margin: 0;
    > * { margin-right: 10px; }
    > :last-child { margin-right: 0; }
    li {
      padding: 4px 6px;
      color: #fff;
      border-radius: 5px;
    }
  }
`

const WordTargets = styled.div`
  h4 {
    margin: 0;
    margin-bottom: 8px;
  }
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    > * { margin-right: 10px; }
    > :last-child { margin-right: 0; }
    li {
      padding: 4px 6px;
      color: #fff;
      border-radius: 5px;
    }
  }
`

const WritingPrompt = () => {
  const { assignment, subscription } = useAssignmentReview()

  if (!assignment.writingPrompt) return null

  const checkTarget = (text: string, target: any) => {
    let n = 0
    if (target.match && target.matchCount) {
      const count = (text.match(new RegExp(target.match, 'g')) || []).length
      return count >= target.matchCount
    }
    if (target.match && !new RegExp(target.match).test(text)) {
      n += 1
    }
    if (target.notMatch && new RegExp(target.notMatch, 'g').test(text)) {
      n += 1
    }
    return n === 0
  }

  return <Wrapper>
    { assignment.zookeeper &&
      <div tw='flex'>
        <div>
          <Avatar user={assignment.zookeeper} />
        </div>
        <div tw='flex flex-col align-middle justify-center ml-4'>
          <div tw='text-sm font-bold'>{assignment.zookeeper.username}</div>
          <div>Year group: {assignment.zookeeper.yearGroup}</div>
        </div>
      </div>
    }
    <div>{assignment.title}</div>
    <Title>{assignment.writingPrompt.title}</Title>
    <Content dangerouslySetInnerHTML={{ __html: assignment.writingPrompt.description }} />
    { (assignment.writingPrompt.targets || []).length > 0 && <Targets>
      <h4>Targets</h4>
      <ul>
        { assignment.writingPrompt.targets.map(t => <li style={{
          backgroundColor: checkTarget(assignment.writing.text, t) ? '#8CC63F' : '#FB354E'
        }} dangerouslySetInnerHTML={{ __html: t.title }} />)}
      </ul>
    </Targets> }
    { (assignment.writingPrompt.wordBank || []).length > 0 && <WordTargets>
      <h4>Word bank</h4>
      <ul>
        { assignment.writingPrompt.wordBank.map(word => <li style={{
          backgroundColor: checkTarget(assignment.writing.text, word) ? '#8CC63F' : '#FB354E'
        }}>
          {word.title}
        </li>)}
      </ul>
    </WordTargets> }
    <div>
      <h4 tw='mb-2'>Parent message: </h4><p tw='my-0'>{subscription?.message || 'No message'}</p>
    </div>
  </Wrapper>
}

export default WritingPrompt
